import React from 'react'

class Skill extends React.Component {
    render() {
        const {icon, name} = this.props.skill
        return( 
            <div className="skills-container">
                <div className="skills-imgContainer">
                    <img className="skills-imgContainer-img" src={icon} alt="AWS" />
                </div>
                <div className="skills-name">{name}</div>
            </div>
        )
    }
}

export default Skill;