import React from 'react'

class AboutMe extends React.Component {
    render() {
        return( 
            <div className="aboutMeContainer" id="scroll-about">
                <h1 className="aboutMe-header">About Me</h1>
                <p className="aboutMe-body">Hello, my name is Nikita Podberezkin. Graduate of UC Santa Cruz in 2017 with Bachelor degree in Computer Science. 
                    I am Software Developer who likes building awesome software and currently lives in San Mateo, California.    
                    I enjoy building everything from small sites to rich interactive web apps. Skilled with web application development, cloud hosing and Database.
                      </p>    
            </div>
        )
    }
}

export default AboutMe