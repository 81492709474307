import React from 'react'
import laptop from '../img/laptop.jpg'
import face from '../img/face.png'

class MainImage extends React.Component {
    render() {
        return( 
            <div className="imageContainer">
                <div className="mainImage">
                    <img src={laptop} className="mainImage-photo" alt="Cover" />
                </div>
                <div className="coverImage">
                    <img src={face} className="coverImage-photo" alt="Nikita Podberezkin" />
                </div>
            </div>
        )
    }
}

export default MainImage