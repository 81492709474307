import React from 'react';
import Navbar from './components/Navbar';
import AboutMe from './components/AboutMe';
import ContactInfo from './components/ContactInfo';
import Projects from './components/Projects';
import MainImage from './components/MainImage';
import Footer from './components/Footer'
import Skills from '../src/components/Skills'
import Resume from '../src/components/Resume'

import './App.css';

class App extends React.Component {

    render() {
      return (
        <div>
          <Navbar />
          <MainImage />
          <div className="mainPage">
            <Resume />
            <AboutMe />
            <Skills />
            <Projects />
            <ContactInfo />
          </div>
          <Footer />
        </div>
      );
    }
}

export default App;
