import React from 'react'

class Project extends React.Component {
    render() {
        const {data} = this.props
        return( 
            <div className="projectContainer">
                <div className="project-shape">
                    <img alt={data.name} src={data.img} className="project-shape-img" />
                </div>
                <div className="project">
                    <h2 className="project-name">{data.name}</h2>
                    <div className="project-description">
                        {data.description}
                    </div>
                    <div className="project-links">
                        <a href={data.demo} className="project-link" target="_blank" rel="noreferrer noopener">Demo</a>
                        <a href={data.github} className="project-link" target="_blank" rel="noreferrer noopener">Github</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Project