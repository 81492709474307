import React from 'react'
import linkedin from '../img/linkedin.png'
import mail from '../img/mailT.png'
import github from '../img/github.png'

class ContactInfo extends React.Component {
    render() {
        return( 
            <div className="contactContainer" id="scroll-contact">
                <div className="contact-img"></div>
                <h1 className="contact-title">Contact Info</h1>
                <div className="contact-body">
                    <div className="contact-body-item">
                        <div className="contact-body-icon">
                            <img src={mail} alt="Email" className="contact-body-icon-img" />
                        </div>
                        <div className="contact-body-title">Email:</div>
                        <div className="contact-body-info">npodberezkin@gmail.com</div>
                    </div>
                    <div className="contact-body-item">
                        <div className="contact-body-icon">
                            <img src={linkedin} alt="Linkedin" className="contact-body-icon-img" />
                        </div>
                        <a href="https://www.linkedin.com/in/nikita-podberezkin-a87161156/" className="contact-body-link" target="_blank" rel="noreferrer noopener">Linkedin</a>
                    </div>
                    <div className="contact-body-item">
                        <div className="contact-body-icon">
                            <img src={github} alt="Linkedin" className="contact-body-icon-img" />
                        </div>
                        <a href="https://github.com/npodbere" className="contact-body-link" target="_blank" rel="noreferrer noopener">Github</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactInfo