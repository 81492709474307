import React from 'react'
import Skill from './Skill'
import { skills } from '../data/data'

class Skills extends React.Component {

    renderSkills = (skills) => {
       return skills.map(skill => {
            return <Skill key={skill.name} skill={skill} />
        })
    }

    render() {
        let firstSkillsHalf = skills.slice(0, Math.ceil(skills.length/2))
        let secondSkillsHalf = skills.slice(Math.floor(skills.length/2)+1, skills.length)     
        return( 
            <div className="skills">
                <div className="skills-title">My Skills</div>
                <div className="skills-line">{this.renderSkills(firstSkillsHalf)}</div>
                <div className="skills-line">{this.renderSkills(secondSkillsHalf)}</div>
            </div>
        )
    }
}

export default Skills;