import React from 'react'
import {animateScroll as scroll, scroller } from 'react-scroll'


class Navbar extends React.Component {

   ContactScrolling = (id) => {
        scroller.scrollTo(id, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
    }

    render() {
        return( 
            <div className="navbar">
                <button onClick={() => scroll.scrollToTop()} className="navButton">About me</button>  
                <button className="navButton" onClick={() => this.ContactScrolling('scroll-projects')}>Projects</button>  
                <button className="navButton" onClick={() => this.ContactScrolling('scroll-contact')}>Contact Info</button>       
            </div>
        )
    }
}

export default Navbar