import Naturous from '../img/projects/Natours.JPG'
import Nexter from '../img/projects/Nexter.JPG'
import Trillo from '../img/projects/Trillo.JPG'
import WeatherApp from '../img/projects/Weather.JPG' 

import aws from '../img/skills/aws.png'
import css from '../img/skills/css.png'
import es6 from '../img/skills/es6.png'
import html5 from '../img/skills/html5.png'
import java from '../img/skills/java.png'
import js from '../img/skills/js.png'
import python from '../img/skills/python.png'
import react from '../img/skills/react.png'
import redux from '../img/skills/redux.png'
import sass from '../img/skills/sass.png'
import SQL from '../img/skills/SQL.png'

export const projectList = [
    {
        id: 1,
        name:"Naturous",
        img: Naturous,
        description: "This project was made to demonstrate SCSS skills, using only HTML and SCSS using floats. There is no active functionality",
        demo: "https://csstutorialprojects.s3.us-east-2.amazonaws.com/Natours/index.html",
        github: "https://github.com/npodbere/Natours"
    },
    {
        id: 2,
        name:"Nexter",
        img: Nexter,
        description: "This project was made to demonstrate SCSS skills, using only HTML and SCSS using Flexbox. There is no active functionality",
        demo: "https://csstutorialprojects.s3.us-east-2.amazonaws.com/Nexter/index.html",
        github: "https://github.com/npodbere/Nexter"
    },
    {
        id: 3,
        name:"Trillo",
        img: Trillo,
        description: "This project was made to demonstrate SCSS skills, using only HTML and SCSS using CSS Grid. There is no active functionality",
        demo: "https://csstutorialprojects.s3.us-east-2.amazonaws.com/Trillo/index.html",
        github: "https://github.com/npodbere/Trillo/"
    },
    {
        id: 4,
        name:"Weather app",
        img: WeatherApp,
        description: "This project was made to demonstrate React,Redux, using JavaScript and Google API. Enter the name of the city in United States and click Submit. This project is work in progress",
        demo: "https://reduxprojectstutorial.s3.us-east-2.amazonaws.com/Redux2/index.html",
        github: "https://github.com/npodbere/WeatherApp"
    }
]

export const skills = [
    {
        name: "AWS",
        icon: aws
    },
    {
        name: "CSS",
        icon: css
    },
    {
        name: "ES6",
        icon: es6
    },
    {
        name: "HTML",
        icon: html5
    },
    {
        name: "Java",
        icon: java
    },
    {
        name: "JavaScript",
        icon: js
    },
    {
        name: "Python",
        icon: python
    },
    {
        name: "React",
        icon: react
    },
    {
        name: "Redux",
        icon: redux
    },
    {
        name: "SASS",
        icon: sass
    },
    {
        name: "SQL",
        icon: SQL
    }
]

