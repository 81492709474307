import React from 'react'
import pdf from '../img/pdf.png'
import document from '../Nikita_Podberezkin_Resume.pdf'


class Resume extends React.Component {
    render() {
        return( 
            <div className="resumeContainer">
                <div className="resume-title">My Resume</div>
                <div className="resume-body">
                    <div className="resume-body-icon">
                        <img src={pdf} alt="pdf" className="resume-body-img" />
                    </div>
                    <a href="https://portfolio-qa.s3.us-east-2.amazonaws.com/Nikita_Podberezkin_Resume.pdf" className="resume-body-view" target="_blank" rel="noreferrer noopener">View</a>
                    <a href={document} className="resume-body-download" download="Nikita-Podberezkin-resume.pdf" target="_blank" rel="noreferrer noopener">Download</a>
                </div>
            </div>
        )
    }
}

export default Resume