import React from 'react'
import Project from './Project'
import { projectList } from '../data/data'

class Projects extends React.Component {
    render() {
        return( 
            <div className="projects" id="scroll-projects">
                <h1 className="projects-title">Projects</h1>
                <div className="projectsContainer">
                    <div className="row">
                        <Project data={projectList[0]} />
                        <Project data={projectList[1]} />
                    </div>
                    <div className="row">
                        <Project data={projectList[2]} />
                        <Project data={projectList[3]} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Projects