import React from 'react'

class Footer extends React.Component {
    render() {
        return( 
            <footer className="footer">
               &copy; by Nikita Podberezkin
            </footer>
        )
    }
}

export default Footer